import { motion, useAnimation } from "framer-motion";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import styles from "./Offer.module.scss";
import SingleOffer from "./SingleOffer";
import offer1 from "../../../images/home/offer/offer1.webp";
import offer2 from "../../../images/home/offer/offer2.webp";
import offer3 from "../../../images/home/offer/offer3.webp";
import offer4 from "../../../images/home/offer/offer4.webp";
import offer5 from "../../../images/home/offer/offer5.webp";
import offer6 from "../../../images/home/offer/offer6.webp";
import OfferConfig from "../../../config/OfferConfig";
import { useLocation } from "react-router-dom";
import HelmetContent from "../../../helpers/HelmetContent";
import BackButton from "../../../helpers/back-button/BackButton";
import Breadcrumbs from "../../../helpers/breadcrumbs/Breadcrumbs";

const Offer = () => {
  const location = useLocation();
  const offerConfig = OfferConfig;
  const controls = useAnimation();
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.2 });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <>
      {location.pathname === "/" ? (
        ""
      ) : (
        <HelmetContent
          title="Oferta - Podolog Łódź Hallux Clinic"
          desc="Sprawdź naszą ofertę zabiegów podologicznych w Łodzi."
        />
      )}
      <motion.div
        ref={ref}
        initial="hidden"
        animate={controls}
        variants={{
          hidden: { opacity: 0, y: 50 },
          visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
        }}
        className={`${styles.offerWrapper} container`}
      >
        {location.pathname !== "/" && <Breadcrumbs />}
        <div className={styles.offer}>
          {location.pathname !== "/" && <BackButton />}
          <h1 className={styles.offerTitle}>Zabiegi Podologiczne</h1>
          <p className={styles.offerText}>
            Dbaj o swoje stopy! Zabiegi podologiczne to sposób na zdrowe i
            zadbane stopy. Usuń odciski. modzele i grzybicę. Zarezerwuj wizytę
            już dziś!
          </p>
          <div className={styles.singleOfferWrapper}>
            {offerConfig.map((offer, index) => (
              <SingleOffer
                key={index}
                name={offer.mainTitle}
                link={`${location.pathname === "/" ? "usluga/" : ""}${offer.id}`}
                image={[offer1, offer2, offer3, offer4, offer5, offer6][index]}
              />
            ))}
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default Offer;
