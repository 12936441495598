import styles from './AdminNavigation.module.scss'
const AdminNavigation = () => {
    return(
        <nav className={styles.adminNavigation}>
            <div className={'container'}>

            </div>
        </nav>
    )
}

export default AdminNavigation;