import { motion } from "framer-motion";
import styles from "./Header.module.scss";
import headerLogo1500 from "../../../images/home/header/header-logo-1500.webp";
import headerLogo600 from "../../../images/home/header/header-logo-600.webp";
import headerLogo450 from "../../../images/home/header/header-logo-450.webp";
import logo from "../../../images/home/header/logo.webp";
import Circle from "../../../layouts/circle/Circle";
import { BsTelephoneFill } from "react-icons/bs";

const fadeInVariant = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 1, ease: "easeOut" } },
};

const Header = () => {
  return (
    <div style={{ background: "#FFD3CAFF" }}>
      <motion.div
        className={styles.headerWrapper}
        initial="hidden"
        animate="visible"
        variants={fadeInVariant}
      >
        <header className={styles.header}>
          <div className={styles.logoWrapper}>
            <motion.picture
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ duration: 1, ease: "easeOut" }}
            >
              <source
                className={styles.logo}
                media="(min-width: 600px)"
                srcSet={headerLogo1500}
              />
              <source
                className={styles.logo}
                media="(min-width: 450px)"
                srcSet={headerLogo600}
              />
              <source
                className={styles.logo}
                media="(min-width: 1px)"
                srcSet={headerLogo450}
              />
              <img
                className={styles.logo}
                src={headerLogo1500}
                alt="Header logo"
              />
            </motion.picture>
            <Circle size="medium" left="-100px" bottom="0" />
          </div>

          <motion.div
            className={styles.headerSection}
            initial="hidden"
            animate="visible"
            variants={fadeInVariant}
          >
            <img
              className={styles.logo}
              src={logo}
              alt="Mobilny Podolog Łódź - Hallux.Clinic"
            />
            <h1 className={styles.headerText}>
              <span className={styles.headerTextTitle}>
                <motion.span
                  className={styles.firstLetter}
                  initial={{ x: -50, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ delay: 0.3 }}
                >
                  M
                </motion.span>
                obilny
              </span>
              <span
                className={`${styles.headerTextTitle} ${styles.secondTitle}`}
              >
                <motion.span
                  className={styles.firstLetter}
                  initial={{ x: -50, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ delay: 0.6 }}
                >
                  G
                </motion.span>
                abinet
              </span>
              <span
                className={`${styles.headerTextTitle} ${styles.thirdTitle}`}
              >
                <motion.span
                  className={styles.firstLetter}
                  initial={{ x: -50, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ delay: 0.9 }}
                >
                  P
                </motion.span>
                odologiczny
              </span>
              <span
                className={`${styles.headerTextTitle} ${styles.fourthTitle}`}
              >
                <motion.span
                  className={styles.firstLetter}
                  initial={{ x: -50, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ delay: 1.2 }}
                >
                  Ł
                </motion.span>
                ódź
              </span>
            </h1>
            <motion.a
              href="tel:+48459410096"
              className={styles.callButton}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              <BsTelephoneFill className={styles.phoneIcon} /> Zadzwoń
            </motion.a>
            <motion.p
              className={`${styles.bottomText} ${styles.mainText}`}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 1.5 }}
            >
              <span className={styles.arrow}>&gt;&gt;&gt;&gt;</span> Pielęgnacja
              Stóp z Dojazdem * GRATIS *
              <span className={styles.arrow}>&lt;&lt;&lt;&lt;</span>
            </motion.p>
          </motion.div>
        </header>
        <Circle size="veryBig" right="-75px" bottom="-150x" />
      </motion.div>
    </div>
  );
};

export default Header;
